<template>
  <div class="misc-wrapper">
    <b-link class="brand-logo mt-0 mb-0">
      <img
        :src="style.loginImage"
        width="150px"
      >
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h3 class="mb-1">
          Ocorreu um erro inesperado ao carregar as informações do PDV ❌
        </h3>
        <p class="mb-3">
          Por favor clique em "Sair" caso o erro persista no próximo login, entre em contato com o
          suporte.
        </p>

        <!-- <b-button
          variant="primary"
          class="mb-1 mr-1 btn-sm-block"
          @click="refresh"
        >
          Atualizar página
        </b-button> -->
        <b-button
          variant="danger"
          class="mb-1 btn-sm-block"
          @click="exit"
        >
          Sair
        </b-button>

        <b-img
          fluid
          :src="imgUrl"
        />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { BLink, BImg, BButton } from 'bootstrap-vue'
import store from '@/store/index'
import { app, payboxConfiguration } from '@/mixins'

export default {
  components: {
    BLink,
    BImg,
    BButton,
  },
  mixins: [app, payboxConfiguration],
  data() {
    return {
      downImg: require('@/assets/images/pages/pay-box-unknown-error.svg'),
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/pay-box-unknown-error-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
  },
  methods: {
    refresh() {
      this.mxPdvRouteRedirect()
    },
    exit() {
      this.doAppLogout(true)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
